// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brincadeiras-js": () => import("./../../../src/pages/brincadeiras.js" /* webpackChunkName: "component---src-pages-brincadeiras-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-produtos-js": () => import("./../../../src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-pages-template-produto-js": () => import("./../../../src/pagesTemplate/produto.js" /* webpackChunkName: "component---src-pages-template-produto-js" */)
}

